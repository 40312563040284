// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-investor-645193656683-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://u8ou8u5500.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_LzF9NXcPy",
    APP_CLIENT_ID: "7e431jde6iiihe12hj8b9col0k",
    IDENTITY_POOL_ID: "eu-central-1:27e58c9e-1662-43ca-b841-b1851b6f55bc",
  },
  locales: {
    URL: "https://locales.platform.beta.beteiligungsboerse.net",
  },
  datastore: {
    URL: "https://datastore.platform.beta.beteiligungsboerse.net",
  },
  registration: {
    URL: "https://registration.platform.beta.beteiligungsboerse.net/v1",
    NEXT_PAGE: "https://app.investor.beta.beteiligungsboerse.net",
    TO_REFERRER: "https://www.beteiligungsboerse.eu/register-success/",
  },
  product: {
    PRODUCT_KEY: "investor",
  },
};

export default config;
